class TabController {
    constructor(tabContainerSelector, headerSearchSelector) {
        this.tabContainer = document.querySelector(tabContainerSelector);
        this.headerSearch = document.querySelector(headerSearchSelector);

        if (this.tabContainer) {
            this.tabMenus = this.tabContainer.querySelectorAll('.tab__menu_item');
            this.tabPanels = this.tabContainer.nextElementSibling.querySelectorAll('.tab__panel_box');

            // ページロード時のタブ切り替え処理
            this.loadTabFromURL();

            // タブクリックイベントを初期化
            this.initTabSwitch();
        }
    }

    // タブ切り替え機能の初期化
    initTabSwitch() {
        this.tabMenus.forEach(tabMenu => {
            tabMenu.addEventListener('click', (e) => this.switchTab(e.currentTarget));
        });
    }

    // 指定したタブに切り替える
    switchTab(tabMenu) {
        const targetTab = tabMenu.dataset.tab;

        // 全タブとパネルのアクティブ状態をリセット
        this.resetTabs();

        // 対象タブと対応するパネルをアクティブ化
        tabMenu.classList.add('is-active');
        this.tabPanels.forEach(panel => {
            if (panel.dataset.panel === targetTab) {
                panel.classList.add('is-show');
            }
        });

        // URLを更新
        this.updateURL(targetTab);
    }

    // 全てのタブとパネルのアクティブ状態をリセット
    resetTabs() {
        this.tabMenus.forEach(tab => tab.classList.remove('is-active'));
        this.tabPanels.forEach(panel => panel.classList.remove('is-show'));
    }

    // URLのクエリパラメータからタブを読み取り切り替え
    loadTabFromURL() {
        const urlParams = new URLSearchParams(window.location.search);
        const tabParam = urlParams.get('tab');

        if (tabParam) {
            const targetTab = Array.from(this.tabMenus).find(tab => tab.dataset.tab === tabParam);
            if (targetTab) {
                this.switchTab(targetTab);
            }
        }
    }

    // URLのクエリパラメータを更新
    updateURL(tab) {
        const url = new URL(window.location.href);
        url.searchParams.set('tab', tab);
        window.history.replaceState(null, '', url.toString());
    }
}

// 初期化
document.addEventListener('DOMContentLoaded', () => {
    new TabController('.tab__menu', '.header__search');
});
